<template>
<div :key="componentKey">
    <div v-if="!isMobile()" >
        <div class="page page-composition-inclusive">
        <full-page class="slider-block-main slider-block-composition" ref="fullpageCP" :options="options" id="fullpage">
            <div class="section">
                <div id="first-page" class="slide slider-item" data-anchor="section-first">
                    <!--Section one-->
                    <div class="page-section section-first" @wheel.prevent="handleWheelMainCompo">
                        <VideoCompositionInclusive/>
                        <div class="page-section-col block-text" :class="{'d-block': showBloc}">
                            <div>
                                <h1 class="page-title opacity-0" :class="{'anim-slide-right-blur':AnimtxtBlur}">{{$t('titleCompo')['0']}}
                                <br> {{$t('titleCompo')['1']}}</h1>
                            </div>
                        </div>

                        <div class="scroll-right">
                            <a href="#" class="btn btn-white btn-scroll" v-on:click="scrollToNext">  
                            <svg width="12" height="22" viewBox="0 0 12 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M1 1.20101L10.8995 11.1005L1 21" stroke="#6CB0BD" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                            <svg width="12" height="22" viewBox="0 0 12 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M1 1.20101L10.8995 11.1005L1 21" stroke="#6CB0BD" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                            </a>
                        </div>
                    </div>
                    <!--Section One-->
                </div>

                <div class="slide slider-item" data-anchor="section-image">
                    <!--Section Image-->
                    <div class="page-section section-image" @wheel.prevent="handleWheelMainCompo">
                        <div class="page-section-col">
                            <picture class="loadTemp"> 
                                <source srcset="" :dta-srcset='this.$pathprod+"images/composition-inclusive/composition-inclusive-bg-2.webp"' type="image/webp"> 
                                <img src="" :dta-src='this.$pathprod+"images/composition-inclusive/composition-inclusive-bg-2.jpg"' srcset="" :dta-srcset='this.$pathprod+"images/composition-inclusive/composition-inclusive-bg-2-retina.jpg 2x"' alt="composition-inclusive-bg-2" width="1920" height="937">
                            </picture>
                        </div>
                    </div>
                    <!--Section Image-->
                </div>

                <div class="slide slider-item" data-anchor="esquisse-projet">
                    <!--Section Geste esquisse projet-->
                    <div class="page-section section-esquisse-projet" id="projet-esquisse">
                        <div class="page-section-col page-section-scrollable" @wheel="wheelIntersection">
                            <div class="esquisse-projet-content">
                                <div class="esquisse-projet-content-col">
                                    <div class="esquisse-text-item">
                                        <div class="esquisse-top-info">
                                            <div class="esquisse-projet-top">
                                                <h2 class="title-h2 anim-txt-focus">{{$t('dataEsquisse')['0']}}</h2>
                                            </div>
                                            <div class="esquisse-projet-top-img">
                                                <img :src='this.$pathprod+"images/logo/logo-dgm.svg"' alt="logo-dgm" width="64" height="64">
                                                <img :src='this.$pathprod+"images/logo/logo-bb-studio-architecture.svg"' alt="logo-bb-studio-architecture" width="64" height="64">
                                            </div>
                                            <div class="esquisse-bottom-top">
                                                <p class="text-turquoise">{{$t('dataEsquisse')['1']}}</p>
                                            </div>
                                        </div>
                                        <p class="esquisse-texte">
                                            {{$t('dataEsquisse')['2']}}
                                        </p>
                                        <p class="esquisse-texte">
                                            {{$t('dataEsquisse')['3']}}
                                        </p>
                                    </div>
                                    <div class="esquisse-text-item">
                                        <p class="esquisse-texte">
                                            <strong>{{$t('dataEsquisse')['4']}}</strong>
                                        </p>
                                        <p class="esquisse-texte">
                                            <strong>{{$t('dataEsquisse')['5']}}</strong>
                                        </p>
                                        <p class="esquisse-texte">
                                            {{$t('dataEsquisse')['6']}}<strong>{{$t('dataEsquisse')['7']}}</strong> {{$t('dataEsquisse')['8']}}
                                        </p>
                                        <p class="esquisse-texte">
                                            {{$t('dataEsquisse')['9']}}<strong>{{$t('dataEsquisse')['10']}}</strong> {{$t('dataEsquisse')['11']}}
                                        </p>
                                        <p class="esquisse-texte hide-under-360">
                                            {{$t('dataEsquisse')['12']}} <strong>{{$t('dataEsquisse')['13']}}</strong> {{$t('dataEsquisse')['14']}}
                                        </p>
                                    </div>
                                    <div class="esquisse-text-item">
                                        <p class="esquisse-texte show-under-360">
                                            {{$t('dataEsquisse')['12']}} <strong>{{$t('dataEsquisse')['13']}}</strong> {{$t('dataEsquisse')['14']}}
                                        </p>
                                        <p class="esquisse-texte">
                                            {{$t('dataEsquisse')['15']}} <strong>{{$t('dataEsquisse')['16']}}</strong>{{$t('dataEsquisse')['17']}}
                                        </p>
                                        <p class="esquisse-texte hide-under-360">
                                            {{$t('dataEsquisse')['18']}}
                                            <strong>{{$t('dataEsquisse')['19']}}</strong>
                                        </p>
                                        <p class="esquisse-texte hide-under-360">
                                            {{$t('dataEsquisse')['20']}}
                                        </p>
                                    </div>
                                    <div class="esquisse-text-item">
                                        <p class="esquisse-texte show-under-360">
                                            {{$t('dataEsquisse')['18']}} 
                                            <strong>{{$t('dataEsquisse')['19']}}</strong>
                                        </p>
                                        <p class="esquisse-texte show-under-360">
                                            {{$t('dataEsquisse')['20']}}
                                        </p>
                                        <p class="esquisse-texte">
                                            {{$t('dataEsquisse')['21']}} <strong>{{$t('dataEsquisse')['22']}}</strong>{{$t('dataEsquisse')['23']}}
                                        </p>
                                        <p class="esquisse-texte hide-under-360">
                                            {{$t('dataEsquisse')['24']}} <strong>{{$t('dataEsquisse')['25']}}</strong> 
                                            {{$t('dataEsquisse')['26']}}
                                        </p>
                                        <p class="esquisse-texte hide-under-360">
                                            {{$t('dataEsquisse')['27']}}
                                        </p>
                                        <p class="esquisse-texte hide-under-360">
                                            {{$t('dataEsquisse')['28']}}
                                        </p>
                                    </div>
                                    <div class="esquisse-text-item">
                                        <p class="esquisse-texte show-under-360">
                                            {{$t('dataEsquisse')['24']}} <strong>{{$t('dataEsquisse')['25']}}</strong> 
                                            {{$t('dataEsquisse')['26']}}
                                        </p>
                                        <p class="esquisse-texte show-under-360">
                                            {{$t('dataEsquisse')['27']}}
                                        </p>
                                        <p class="esquisse-texte show-under-360">
                                            {{$t('dataEsquisse')['28']}}
                                        </p>

                                        <p class="esquisse-texte hide-under-360">
                                            {{$t('dataEsquisse')['29']}}
                                        </p>
                                        <p class="esquisse-texte hide-under-360">
                                            {{$t('dataEsquisse')['30']}}
                                        </p>
                                        <p class="esquisse-texte hide-under-360">
                                            {{$t('dataEsquisse')['31']}}
                                        </p>
                                        <p class="esquisse-signature hide-under-360">
                                            <strong>Veronique Mauer</strong>
                                            {{$t('dataEsquisse')['32']}}
                                        </p>
                                        <p class="esquisse-signature hide-under-360">
                                            <strong>Claudia Van den Berg</strong>
                                            {{$t('dataEsquisse')['33']}}
                                        </p>
                                    </div>
                                    <div class="esquisse-text-item show-under-360">
                                        <p class="esquisse-texte">
                                            {{$t('dataEsquisse')['29']}}
                                        </p>
                                        <p class="esquisse-texte">
                                            {{$t('dataEsquisse')['30']}}
                                        </p>
                                        <p class="esquisse-texte">
                                            {{$t('dataEsquisse')['31']}}
                                        </p>
                                    </div>
                                    
                                    <div class="esquisse-text-item show-under-360">
                                        <p class="esquisse-signature">
                                            <strong>Veronique Mauer</strong>
                                            {{$t('dataEsquisse')['32']}}
                                        </p>
                                        <p class="esquisse-signature">
                                            <strong>Claudia Van den Berg</strong>
                                            {{$t('dataEsquisse')['33']}}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="page-section-col">
                            <div class="esquisse-img-block esquisse-img-custom">
                                <figure class="img-urbain">
                                    <picture class="loadTemp"> 
                                        <source srcset="" :dta-srcset='this.$pathprod+"images/composition-inclusive/composition-inclusive-fenetres-urbains-2.webp"' type="image/webp"> 
                                        <img src="" :dta-src='this.$pathprod+"images/composition-inclusive/composition-inclusive-fenetres-urbains-2.png"' srcset="" :dta-srcset='this.$pathprod+"images/composition-inclusive/composition-inclusive-fenetres-urbains-2-retina.png 2x"' alt="composition-inclusive-fenetres-urbains-2" width="607" height="323">
                                    </picture>
                                </figure>
                            </div>
                        </div>
                    </div>
                    <!--Section Geste esquisse projet-->
                </div>

                <div class="slide slider-item section-esquisse-projet" data-anchor="section-image2">
                    <div class="page-section section-image" @wheel.prevent="handleWheelMainCompo">
                        <div class="bg-esquisse bg-esquisse-custom">            
                            <figure class="esquisse-img-bloc">
                                <picture class="loadTemp"> 
                                    <source srcset="" :dta-srcset='this.$pathprod+"images/composition-inclusive/composition-inclusive-all.webp"' type="image/webp"> 
                                    <img src="" :dta-src='this.$pathprod+"images/composition-inclusive/composition-inclusive-all.jpg"' srcset="" :dta-srcset='this.$pathprod+"images/composition-inclusive/composition-inclusive-all.png 2x"' alt="composition-inclusive-all"> 
                                </picture>
                            </figure>
                        </div>
                    </div>
                </div>

                <div class="slide slider-item" data-anchor="espace-rassembleurs">
                    <div class="page-section section-espaces-rassembleurs section-espaces-rass"  @wheel.prevent="wheelIntersectionEspaceRassembleurs" id="rassembleurs-espace">
                        <div class="dynamique-images-block">
                            <div class="dynamique-images-flou dynamic-images drop-attachment-fixed">
                                <picture class="loadTemp"> 
                                    <source srcset="" :dta-srcset='this.$pathprod+"images/composition-inclusive/composition-inclusive-Hall-Lobby-contrast.webp"' type="image/webp"> 
                                    <img src="" :dta-src='this.$pathprod+"images/composition-inclusive/composition-inclusive-Hall-Lobby-contrast.jpg"' srcset="" :dta-srcset='this.$pathprod+"images/composition-inclusive/composition-inclusive-Hall-Lobby-contrast-retina.jpg 2x"' alt="composition-inclusive-Hall-Lobby-contrast" width="1920" height="937"> 
                                </picture>
                                <div class="block-turquoise-dynamique">
                                    <p class="block-gold-text">{{$t('titleES')}}</p>
                                </div>
                            </div>
                            <div class="dynamique-images-full dynamic-images drop-attachment-fixed">
                                <picture class="loadTemp"> 
                                    <source srcset="" :dta-srcset='this.$pathprod+"images/composition-inclusive/composition-inclusive-Hall-Lobby-6.webp"' type="image/webp"> 
                                   <img src="" :dta-src='this.$pathprod+"images/composition-inclusive/composition-inclusive-Hall-Lobby-6.jpg"' srcset="" :dta-srcset='this.$pathprod+"images/composition-inclusive/composition-inclusive-Hall-Lobby-6-retina.jpg 2x"' alt="composition-inclusive-Hall-Lobby-6" width="1920" height="937"> 
                                </picture>
                            </div>
                            <div class="block-key-number" :class="{'increment': incrementE}">
                                    <div class="block-key-left"></div>
                                    <div class="block-key-right">
                                        <div class="block-turquoise-number">
                                        <picture class="loadTemp"> 
                                            <source srcset="" :dta-srcset='this.$pathprod+"images/composition-inclusive/composition-inclusive-space-img-1.webp"' type="image/webp"> 
                                            <img src="" :dta-src='this.$pathprod+"images/composition-inclusive/composition-inclusive-space-img-1.jpg"' srcset="" :dta-srcset='this.$pathprod+"images/composition-inclusive/composition-inclusive-space-img-1-retina.jpg 2x"' alt="composition-inclusive-space-img-1" class="espace-img-1" width="190" height="387">
                                        </picture>
                                        <picture class="loadTemp"> 
                                            <source srcset="" :dta-srcset='this.$pathprod+"images/composition-inclusive/composition-inclusive-space-img-2.webp"' type="image/webp"> 
                                            <img src="" :dta-src='this.$pathprod+"images/composition-inclusive/composition-inclusive-space-img-2.jpg"' srcset="" :dta-srcset='this.$pathprod+"images/composition-inclusive/composition-inclusive-space-img-2-retina.jpg 2x"' alt="composition-inclusive-space-img-2" class="espace-img-2" width="400" height="230">
                                        </picture>
                                        <picture class="loadTemp"> 
                                            <source srcset="" :dta-srcset='this.$pathprod+"images/composition-inclusive/composition-inclusive-space-img-3.webp"' type="image/webp"> 
                                            <img src="" :dta-src='this.$pathprod+"images/composition-inclusive/composition-inclusive-space-img-3.jpg"' srcset="" :dta-srcset='this.$pathprod+"images/composition-inclusive/composition-inclusive-space-img-3-retina.jpg 2x"' alt="composition-inclusive-space-img-3" class="espace-img-3" width="400" height="230">
                                        </picture>
                                        <div class="block-turquoise-number-row">
                                            <div class="block-turquoise-number-col">
                                                <div class="list-item">
                                                    <h4 class="title-h4-white">{{$t('subtitleES')}}</h4>
                                                </div>
                                                <div class="key-number-list">
                                                    <div class="key-number-item">
                                                        <p class="key-number white">{{$t('dataChiffreKeyES')['0']}} 
                                                            <!-- <span class="number-to-animate" data-number="5000">5 000</span> -->
                                                            <number
                                                                animationPaused
                                                                ref="number1"
                                                                :from="0"
                                                                :to="5000"
                                                                :format="theFormat"
                                                                :duration="2"
                                                                easing="Power1.easeOut"/> 
                                                                m<span class="m2">2</span></p>
                                                        <p class="key-desc">{{$t('dataChiffreKeyES')['1']}}</p>
                                                    </div>

                                                    <div class="key-number-item">
                                                        <p class="key-number white">
                                                            <!-- <span class="number-to-animate" data-number="780">780</span>  -->
                                                            <number
                                                                animationPaused
                                                                ref="number2"
                                                                :from="0"
                                                                :to="780"
                                                                :format="theFormat"
                                                                :duration="2"
                                                                easing="Power1.easeOut"/>
                                                                m<span class="m2">2</span></p>
                                                        <p class="key-desc">{{$t('dataChiffreKeyES')['2']}}</p>
                                                    </div>

                                                    <div class="key-number-item">
                                                        <p class="key-number white">
                                                            <!-- <span class="number-to-animate" data-number="7200">7 200</span>  -->
                                                            <number
                                                                animationPaused
                                                                ref="number3"
                                                                :from="0"
                                                                :to="7200"
                                                                :format="theFormat"
                                                                :duration="2"
                                                                easing="Power1.easeOut"/>
                                                                m<span class="m2">2</span></p>
                                                        <p class="key-desc">{{$t('dataChiffreKeyES')['3']}}</p>
                                                    </div>

                                                    <div class="key-number-item">
                                                        <p class="key-number white">
                                                            <!-- <span class="number-to-animate" data-number="310">310</span>  -->
                                                            <number
                                                                animationPaused
                                                                ref="number4"
                                                                :from="0"
                                                                :to="310"
                                                                :format="theFormat"
                                                                :duration="2"
                                                                easing="Power1.easeOut"/>
                                                                m<span class="m2">2</span></p>
                                                        <p class="key-desc">{{$t('dataChiffreKeyES')['4']}}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="block-turquoise-number-col">
                                                <div class="block-col-content">
                                                    <div class="list-item">
                                                        <h4 class="title-h4-black">{{$t('dataChiffreContent')['0']}}</h4>
                                                        <ul class="list-arrow">
                                                            <li>{{$t('dataChiffreContent')['1']}} </li>
                                                            <li>{{$t('dataChiffreContent')['2']}}</li>
                                                            <li>{{$t('dataChiffreContent')['3']}}</li>
                                                            <li>{{$t('dataChiffreContent')['4']}}</li>
                                                            <li>{{$t('dataChiffreContent')['5']}}</li>
                                                            <li>{{$t('dataChiffreContent')['6']}}</li>
                                                        </ul>
                                                    </div>
                                                    <div class="list-item">
                                                        <h4 class="title-h4-black">{{$t('dataChiffreContent')['7']}}</h4>
                                                        <ul class="list-arrow">  
                                                            <li><strong>{{$t('dataChiffreContent')['8']}}</strong> {{$t('dataChiffreContent')['9']}}</li>
                                                            <li><strong>{{$t('dataChiffreContent')['10']}}</strong> {{$t('dataChiffreContent')['11']}} <br> {{$t('dataChiffreContent')['12']}}</li>
                                                            <li><strong>{{$t('dataChiffreContent')['13']}}</strong> {{$t('dataChiffreContent')['14']}} <br> {{$t('dataChiffreContent')['15']}}</li>
                                                        </ul>
                                                    </div>
                                                    <div class="list-item hide-mobile">
                                                        <h4 class="title-h4-black">{{$t('dataChiffreContent')['16']}} m<span class="m2-custom-txt subtitle-m2">2</span></h4>
                                                        <ul class="list-arrow">
                                                            <li>{{$t('dataChiffreContent')['17']}} m<span class="m2-custom-txt">2</span></li>
                                                            <li>{{$t('dataChiffreContent')['18']}} m</li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="block-turquoise-number-col">
                                                <div class="block-col-content">
                                                    <div class="list-item show-mobile">
                                                        <h4 class="title-h4-black">{{$t('dataChiffreContent')['16']}} m<span class="m2-custom-txt subtitle-m2">2</span></h4>
                                                        <ul class="list-arrow">
                                                            <li>{{$t('dataChiffreContent')['17']}} m<span class="m2-custom-txt">2</span></li>
                                                            <li>{{$t('dataChiffreContent')['18']}} m</li>
                                                        </ul>
                                                    </div>
                                                    <div class="list-item">
                                                        <h4 class="title-h4-black">{{$t('dataChiffreContent')['19']}} m<span class="m2-custom-txt subtitle-m2">2</span></h4>
                                                        <ul class="list-arrow">
                                                            <li>{{$t('dataChiffreContent')['20']}} (250 m<span class="m2-custom-txt">2</span>) {{$t('dataChiffreContent')['21']}}</li>
                                                            <li>{{$t('dataChiffreContent')['22']}} (800 m<span class="m2-custom-txt">2</span>)</li>
                                                            <li>{{$t('dataChiffreContent')['23']}}</li>
                                                            <li>{{$t('dataChiffreContent')['24']}}</li>
                                                        </ul>
                                                    </div>
                                                    <div class="list-item hide-mobile">
                                                        <h4 class="title-h4-black">{{$t('dataChiffreContent')['25']}}</h4>
                                                        <ul class="list-arrow">
                                                            <li>{{$t('dataChiffreContent')['26']}}</li>
                                                            <li>{{$t('dataChiffreContent')['27']}}</li>
                                                            <li>{{$t('dataChiffreContent')['28']}}</li>
                                                            <li>{{$t('dataChiffreContent')['29']}}</li>
                                                            <li>{{$t('dataChiffreContent')['30']}}</li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="block-turquoise-number-col show-mobile">
                                                <div class="block-col-content">
                                                    <div class="list-item">
                                                        <h4 class="title-h4-black">{{$t('dataChiffreContent')['25']}}</h4>
                                                        <ul class="list-arrow">
                                                            <li>{{$t('dataChiffreContent')['26']}}</li>
                                                            <li>{{$t('dataChiffreContent')['27']}}</li>
                                                            <li>{{$t('dataChiffreContent')['28']}}</li>
                                                            <li>{{$t('dataChiffreContent')['29']}}</li>
                                                            <li>{{$t('dataChiffreContent')['30']}}</li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    </div>
                                    
                                </div>
                        </div>
                    </div>
                </div>

                <div class="slide slider-item">
                    <!--Section brulerie-->
                    <div class="page-section section-corner" @wheel.prevent="wheelIntersectionImg($event, section_corner)">
                        <div class="page-section-col">
                            <div class="block-image-zoom">
                                <div class="block-img block-image-transparent">
                                    <picture class="loadTemp"> 
                                        <source srcset="" :dta-srcset='this.$pathprod+"images/composition-inclusive/composition-inclusive-croquis-echoppe-contrast.webp"' type="image/webp">  
                                        <img src="" :dta-src='this.$pathprod+"images/composition-inclusive/composition-inclusive-croquis-echoppe-contrast.jpg"' srcset="" :dta-srcset='this.$pathprod+"images/composition-inclusive/composition-inclusive-croquis-echoppe-contrast-retina.jpg 2x"' alt="composition-inclusive-croquis-echoppe" class="img-desk" width="1920" height="937">
                                    </picture>
                                    <picture class="loadTemp"> 
                                        <source srcset="" :dta-srcset='this.$pathprod+"images/composition-inclusive/composition-inclusive-croquis-echoppe-contrast-mobile1.webp"' type="image/webp"> 
                                        <img src="" :dta-src='this.$pathprod+"images/composition-inclusive/composition-inclusive-croquis-echoppe-contrast-mobile1.jpg"' alt="composition-inclusive-croquis-echoppe" class="img-mobile" width="1920" height="937">
                                    </picture>
                                </div>
                                <div class="block-img block-image-full">
                                    <picture class="loadTemp"> 
                                        <source srcset="" :dta-srcset='this.$pathprod+"images/composition-inclusive/composition-inclusive-croquis-echoppe.webp"' type="image/webp"> 
                                        <img src="" :dta-src='this.$pathprod+"images/composition-inclusive/composition-inclusive-croquis-echoppe.jpg"' srcset="" :dta-srcset='this.$pathprod+"images/composition-inclusive/composition-inclusive-croquis-echoppe-retina.jpg 2x"' alt="composition-inclusive-croquis-echoppe" width="1920" height="937">
                                    </picture>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!--Section brulerie-->
                </div>

                <div class="slide slider-item" data-anchor="section-carousel">
                    <!--Section carousel 1-->
                    <div class="page-section section-carousel" @wheel.prevent="wheelIntersectionCarousel($event, carousel1_block,'carousel', 1)">
                        <div class="page-section-col">

                            <hooper ref="carousel1" class="slider-block carousel1-block" :settings="hooperSettings"> 
                                <slide class="slider-item" v-for="carousel in carousel1" :key="carousel.id" :data-key="carousel.id">
                                    <picture> 
                                        <source :srcset="carousel.srcsetWebp" type="image/webp"> 
                                        <img  :src="carousel.src" :srcset="carousel.srcset" :alt="carousel.alt" @click="openLightbox(carousel.id, lightbox1)">
                                    </picture>
                                </slide>
                                <hooper-pagination slot="hooper-addons"></hooper-pagination>
                            </hooper>
                        </div>
                    </div>
                    <!--Section carousel 1-->
                </div>

                <div class="slide slider-item" data-anchor="section-rhapsody-services">
                    <!--Section Rhapsody services-->
                    <div class="page-section section-rhapsody-services" @wheel.prevent="handleWheelMainCompo">
                        <div class="page-section-col">
                            <div class="rhapsody-block">
                                <div class="rhapsody-block-top">
                                    <h2 class="title-h2 anim-txt-focus">{{$t('titleRhapsodySevices')}}</h2>
                                    <picture> 
                                        <source :srcset='this.$pathprod+"images/composition-inclusive/composition-inclusive-bb-studio-architecture-logo.webp"' type="image/webp"> 
                                        <img class="rhapsody-logo" :src='this.$pathprod+"images/composition-inclusive/composition-inclusive-bb-studio-architecture-logo.jpg"' alt="entry-harmony-bb-studio-architecture-logo" width="64" height="64">
                                    </picture>
                                </div>
                                <div class="rhapsody-block-bottom">
                                    <div class="rhapsody-bottom-col anim-slide-left">
                                        <div class="rhapsody-bottom-col-top">
                                            <h2 class="title-h2">{{$t('titleRhapsodySevices')}}</h2>
                                            <picture> 
                                                <source :srcset='this.$pathprod+"images/composition-inclusive/composition-inclusive-bb-studio-architecture-logo.webp"' type="image/webp"> 
                                                <img class="rhapsody-logo" :src='this.$pathprod+"images/composition-inclusive/composition-inclusive-bb-studio-architecture-logo.jpg"' alt="entry-harmony-bb-studio-architecture-logo" width="64" height="64">
                                            </picture>
                                        </div>
                                        <div class="rhapsody-bottom-col-bottom">
                                            <div class="rhapsody-bottom-col-left">
                                                <img class="quote-before" :src='this.$pathprod+"images/composition-inclusive/quote-before.svg"' alt="quote-before" width="39" height="25">
                                            </div>
                                            <div class="rhapsody-bottom-col-right">
                                                <h4 class="title-h4">{{$t('dataRhapsodyServices')['0']}}</h4>
                                                <p class="rhapsody-text">
                                                    {{$t('dataRhapsodyServices')['1']}}
                                                    <img  class="quote-after" :src='this.$pathprod+"images/composition-inclusive/quote-after.svg"' alt="quote-after"  width="17" height="11">
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="rhapsody-bottom-col-bottom anim-slide-right">
                                        <div class="rhapsody-bottom-col-left">
                                            <img  class="quote-before" :src='this.$pathprod+"images/composition-inclusive/quote-before.svg"' alt="quote-before" width="39" height="25">
                                        </div>
                                        <div class="rhapsody-bottom-col-right">
                                            <h4 class="title-h4">{{$t('dataRhapsodyServices')['2']}}</h4>
                                            <p class="rhapsody-text">
                                                {{$t('dataRhapsodyServices')['3']}}<img  class="quote-after" :src='this.$pathprod+"images/composition-inclusive/quote-after.svg"' alt="quote-after"  width="17" height="11">
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!--Section Rhapsody services-->
                </div>

                <div class="slide slider-item" data-anchor="section-plaza">
                    <!--Section La Plaza-->
                    <div class="page-section section-plaza" @wheel.prevent="wheelIntersectionImg($event,section_plaza)">
                        <div class="page-section-col">
                            <div class="block-image-zoom">
                                <div class="block-img block-image-transparent">
                                    <picture class="loadTemp"> 
                                        <source srcset="" :dta-srcset='this.$pathprod+"images/composition-inclusive/composition-inclusive-croquis-plaza-contrast.webp"' type="image/webp"> 
                                        <img src="" :dta-src='this.$pathprod+"images/composition-inclusive/composition-inclusive-croquis-plaza-contrast.jpg"' srcset="" :dta-srcset='this.$pathprod+"images/composition-inclusive/composition-inclusive-croquis-plaza-contrast-retina.jpg 2x"' alt="composition-inclusive-croquis-plaza-contrast" class="img-desk"  width="1920" height="937">
                                    </picture>
                                    <picture class="loadTemp"> 
                                        <source srcset="" :dta-srcset='this.$pathprod+"images/composition-inclusive/composition-inclusive-croquis-plaza-contrast-mobile1.webp"' type="image/webp">
                                        <img src="" :dta-src='this.$pathprod+"images/composition-inclusive/composition-inclusive-croquis-plaza-contrast-mobile1.jpg"' alt="composition-inclusive-croquis-plaza-contrast" class="img-mobile"  width="1920" height="937">
                                    </picture>
                                </div>
                                <div class="block-img block-image-full">
                                    <picture class="loadTemp"> 
                                        <source srcset="" :dta-srcset='this.$pathprod+"images/composition-inclusive/composition-inclusive-croquis-plaza.webp"' type="image/webp">
                                        <img src="" :dta-src='this.$pathprod+"images/composition-inclusive/composition-inclusive-croquis-plaza.jpg"' srcset="" :dta-srcset='this.$pathprod+"images/composition-inclusive/composition-inclusive-croquis-plaza-retina.jpg 2x"' alt="composition-inclusive-croquis-plaza" width="1920" height="937">
                                    </picture>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!--Section La Plaza-->
                </div>

                <div class="slide slider-item" data-anchor="section-destination">
                    <!--Section destination-->
                    <div class="page-section section-destination" @wheel.prevent="wheelIntersectionDestination">
                        <div class="page-section-col">
                            <div class="block-destination">
                                <div class="block-destination-bottom">
                                    <div class="block-bottom-col">
                                        <div class="dest-item">
                                            <h3 class="block-dest-title">{{$t('titleDestinationR')}}</h3>
                                            <h4 class="title-h4-semi">{{$t('dataDestinationR')['0']}} <br class="br-mobile">
                                                {{$t('dataDestinationR')['1']}}
                                            </h4>
                                        </div>
                                        <div class="dest-item">
                                            <h4 class="title-h4-semi">{{$t('dataDestinationR')['2']}}</h4>
                                            <p>
                                                {{$t('dataDestinationR')['3']}}
                                            </p>
                                        </div>
                                        <div class="dest-item">
                                            <h4 class="title-h4-semi">{{$t('dataDestinationR')['4']}} <span class="span-block">{{$t('dataDestinationR')['5']}}</span></h4>
                                            <p>
                                                {{$t('dataDestinationR')['6']}}
                                            </p>
                                        </div>
                                        <div class="dest-item">
                                            <h4 class="title-h4-semi">{{$t('dataDestinationR')['7']}} <span class="span-block">{{$t('dataDestinationR')['8']}}</span></h4>
                                            <p>
                                                {{$t('dataDestinationR')['9']}}
                                            </p>
                                        </div>
                                        <div class="dest-item">
                                            <h4 class="title-h4-semi">{{$t('dataDestinationR')['10']}} <span class="span-block">{{$t('dataDestinationR')['11']}}</span></h4>
                                            <p>
                                                {{$t('dataDestinationR')['12']}}
                                            </p>
                                        </div>
                                        <div class="dest-item">
                                            <h4 class="title-h4-semi">{{$t('dataDestinationR')['13']}}</h4>
                                            <p>
                                                {{$t('dataDestinationR')['14']}} 
                                            </p>
                                        </div>
                                        <div class="dest-item">
                                            <h4 class="title-h4-semi">{{$t('dataDestinationR')['15']}}</h4>
                                            <p>
                                                {{$t('dataDestinationR')['16']}}
                                            </p>
                                        </div>
                                        <div class="dest-item">
                                            <h4 class="title-h4-semi">{{$t('dataDestinationR')['17']}}</h4>
                                            <p>
                                                {{$t('dataDestinationR')['18']}}
                                            </p>
                                        </div>
                                        <!-- <div class="bloc-spacing"></div> -->
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!--Section destination-->
                </div>

                <div class="slide slider-item" data-anchor="section-onglet-rd">
                    <!-- Section Ongle RDC Ultra vivant & mezzanie -->
                    <div class="page-section section-onglet-rdc" @wheel.prevent="handleWheelMainCompo">
                        <div class="page-section-col">
                            <div class="section-tab-content">
                                <div class="tab-details">
                                    <div class="tab-content">
                                        <div class="tab-block-title">
                                            <h3 class="tab-title">{{$t('dataOnglet')}}</h3>
                                        </div>
                                        <div class="tab-img fade-in-right">
                                            <picture> 
                                                <img :src="$t('imgOngletDeskJpg')" alt="composition-inclusive-rdc-ultra-vivant" class="tab-img-desk" width="1920" height="937">
                                            </picture>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- Section Ongle RDC Ultra vivant & mezzanie -->
                </div>

                <div class="slide slider-item" data-anchor="pollinisateur">
                    <!--Section Espaces pollinisateur-->
                    <div class="page-section section-espace-pollinisateur section-espace-poll" @wheel.prevent="wheelIntersectionEspacePoll" id="pollinisateurs">
                        <div class="dynamique-images-block">
                            <div class="dynamique-images-flou dynamic-images drop-attachment-fixed">
                                <picture class="loadTemp"> 
                                    <source srcset="" :dta-srcset='this.$pathprod+"images/composition-inclusive/composition-inclusive-box-contrast.webp"' type="image/webp"> 
                                    <img src="" :dta-src='this.$pathprod+"images/composition-inclusive/composition-inclusive-box-contrast.jpg"' srcset="" :dta-srcset='this.$pathprod+"images/composition-inclusive/composition-inclusive-box-contrast-retina.jpg 2x"' alt="composition-inclusive-box-contrast" width="1920" height="937">
                                </picture>
                                <div class="block-turquoise-dynamique">
                                    <p class="block-gold-text">{{$t('titleEspaceP')}}</p>
                                </div>
                            </div>
                            <div class="dynamique-images-full dynamic-images drop-attachment-fixed">
                                <picture class="loadTemp"> 
                                    <source srcset="" :dta-srcset='this.$pathprod+"images/composition-inclusive/composition-inclusive-box.webp"' type="image/webp"> 
                                    <img src="" :dta-src='this.$pathprod+"images/composition-inclusive/composition-inclusive-box.jpg"' srcset="" :dta-srcset='this.$pathprod+"images/composition-inclusive/composition-inclusive-box-retina.jpg 2x"' alt="composition-inclusive-box" width="1920" height="937">
                                </picture>
                            </div>
                            <div class="block-key-number" :class="{'increment': incrementE}">
                                <div class="block-key-left"></div>
                                <div class="block-key-right">
                                    <div class="block-turquoise-number">
                                        <!-- <img  :src='this.$pathprod+"images/composition-inclusive/composition-inclusive-box-img-1.jpg" srcset="/images/composition-inclusive/composition-inclusive-box-img-1-retina.jpg 2x" alt="composition-inclusive-box-img-1" class="local-img"> -->
                                        <div class="block-turquoise-top hide-mobile">
                                            <h4 class="title-h4-white">{{$t('dataChiffreKeEP')['0']}}<br> {{$t('dataChiffreKeEP')['1']}}</h4>
                                        </div>
                                        <div class="block-turquoise-bottom">
                                            <div class="block-turquoise-number-col">
                                                <h4 class="title-h4-white show-mobile">{{$t('dataChiffreKeEP')['0']}}<br> {{$t('dataChiffreKeEP')['1']}}</h4>
                                                <div class="key-number-list">
                                                    <div class="key-number-item hide-mobile">
                                                        <p class="key-number white">{{$t('dataChiffreKeEP')['2']}}</p>
                                                    </div>
                                                    <div class="key-number-grid">
                                                        <div class="key-number-item key-nb show-mobile">
                                                            <p class="key-number white">{{$t('dataChiffreKeEP')['2']}}</p>
                                                        </div>
                                                        <div class="key-number-item key-nb">
                                                            <p class="key-desc">{{$t('dataChiffreKeEP')['3']}}</p>
                                                            <p class="key-number white">
                                                                <!-- <span class="number-to-animate" data-number="4900">4 900</span> -->
                                                                <number
                                                                    animationPaused
                                                                    ref="number5"
                                                                    :from="0"
                                                                    :to="5000"
                                                                    :format="theFormat"
                                                                    :duration="2"
                                                                    easing="Power1.easeOut"/>
                                                                <br>{{$t('dataChiffreKeEP')['4']}} 
                                                                <!-- <span class="number-to-animate" data-number="5900">5 900</span>  -->
                                                                <number
                                                                    animationPaused
                                                                    ref="number6"
                                                                    :from="0"
                                                                    :to="6000"
                                                                    :format="theFormat"
                                                                    :duration="2"
                                                                    easing="Power1.easeOut"/>
                                                                m<span class="m2">2</span></p>
                                                            <p class="key-desc">{{$t('dataChiffreKeEP')['5']}}</p>
                                                        </div>
                                                        
                                                        <div class="key-number-item hide-mobile key-nb-2">
                                                            <p class="key-number white">
                                                                <!-- <span class="number-to-animate" data-number="1,35">1,35</span> -->
                                                                <number
                                                                    animationPaused
                                                                    ref="number7"
                                                                    :from="0"
                                                                    :to="1.35"
                                                                    :format="theFormatDecimal"
                                                                    :duration="2"
                                                                    easing="Power1.easeOut"/>
                                                                m</p>
                                                            <p class="key-desc">{{$t('dataChiffreKeEP')['6']}}</p>
                                                        </div>

                                                        <div class="key-number-item key-nb key-nb-3">
                                                            <p class="key-number white">
                                                                <!-- <span class="number-to-animate" data-number="2,70">2,70</span>  -->
                                                                <number
                                                                    animationPaused
                                                                    ref="number8"
                                                                    :from="0"
                                                                    :to="2.70"
                                                                    :format="theFormatDecimal"
                                                                    :duration="2"
                                                                    easing="Power1.easeOut"/>
                                                            m</p>
                                                            <p class="key-desc">{{$t('dataChiffreKeEP')['7']}}</p>
                                                        </div>

                                                        <div class="key-number-item key-nb">
                                                            <div class="show-mobile key-number-item-mobile">
                                                                <p class="key-number white">
                                                                    <!-- <span class="number-to-animate" data-number="1,35">1,35</span>  -->
                                                                <number
                                                                animationPaused
                                                                ref="number9"
                                                                :from="0"
                                                                :to="1.35"
                                                                :format="theFormatDecimal"
                                                                :duration="2"
                                                                easing="Power1.easeOut"/>
                                                                m</p>
                                                                <p class="key-desc">{{$t('dataChiffreKeEP')['6']}}</p>
                                                            </div>
                                                            <p class="key-number white">
                                                                <!-- <span class="number-to-animate" data-number="64">64</span>  -->
                                                                <number
                                                                    animationPaused
                                                                    ref="number10"
                                                                    :from="0"
                                                                    :to="64"
                                                                    :format="theFormat"
                                                                    :duration="2"
                                                                    easing="Power1.easeOut"/>
                                                                %</p>
                                                            <p class="key-desc">{{$t('dataChiffreKeEP')['8']}}</p>
                                                        </div>
                                                    </div>
                                                    
                                                </div>
                                            </div>

                                            <div class="block-turquoise-number-col">
                                                <div class="block-col-content">
                                                    <table class="table-dynamic">
                                                        <thead>
                                                            <tr>
                                                                <th>{{$t('dataTableEP')['0']}}</th>
                                                                <th class="tac">{{$t('dataTableEP')['1']}}</th>
                                                                <th class="tar">{{$t('dataTableEP')['2']}} <br> {{$t('dataTableEP')['3']}}</th>
                                                                <th class="tar">{{$t('dataTableEP')['4']}} <br> {{$t('dataTableEP')['5']}} <br> {{$t('dataTableEP')['6']}}</th>
                                                                <th class="tac">{{$t('dataTableEP')['7']}}</th>
                                                                <th class="tar">{{$t('dataTableEP')['8']}} <br> {{$t('dataTableEP')['9']}}</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr v-for="(lignTable, index) in tableVivre" :key="index">
                                                                <td>{{lignTable.niveau}}</td>
                                                                <td class="tac">{{lignTable.sub}} <sup v-if="lignTable.sub">m<span class="m2-custom-txt">2</span></sup></td>
                                                                <td class="tar">{{lignTable.capacitaire}} <span v-if="lignTable.capacitaire">pers.</span></td>
                                                                <td class="tar">{{lignTable.service}} <sup v-if="lignTable.service">m<span class="m2-custom-txt">2</span></sup></td>
                                                                <td class="tac">{{lignTable.subl}}<sup v-if="lignTable.subl">m<span class="m2-custom-txt">2</span></sup></td>
                                                                <td class="tar">{{lignTable.surface}} <sup v-if="lignTable.surface">m<span class="m2-custom-txt">2</span></sup></td>
                                                            </tr>
                                                        </tbody>
                                                        <tfoot>
                                                            <tr>
                                                                <td>TOTAL</td>
                                                                <td class="tac">35 450 m<span class="m2-custom-txt">2</span></td>
                                                                <td class="tar">3 796 {{$t('dataTableEP')['10']}}</td>
                                                                <td class="tar">7 955 m<span class="m2-custom-txt">2</span></td>
                                                                <td class="tar">43 445 m<span class="m2-custom-txt">2</span></td>
                                                                <td class="tar">7 226 m<span class="m2-custom-txt">2</span></td>
                                                            </tr>
                                                        </tfoot>
                                                    </table>

                                                    <span class="indice-table indice-text">{{$t('dataTableEP')['11']}}</span>
                                                </div>
                                            </div>
                                        
                                        </div>
                                    </div>
                                </div>
                                </div>
                        </div>
                    </div>
                    <!--Section Espaces pollinisateur-->
                </div>

                <div class="slide slider-item" data-anchor="section-carousel2">
                    <!--Section carousel 2-->
                    <div class="page-section section-carousel" @wheel.prevent="wheelIntersectionCarousel($event,carousel2_block,'carousel', 2)">
                        <div class="page-section-col">

                            <hooper ref="carousel2" class="slider-block carousel2-block" :settings="hooperSettings"> 
                                <slide class="slider-item" v-for="carousel in carousel2" :key="carousel.id">
                                    <picture> 
                                        <source :srcset="carousel.srcsetWebp" type="image/webp"> 
                                        <img  :src="carousel.src" :srcset="carousel.srcset" :alt="carousel.alt" @click="openLightbox2(carousel.id, lightbox2)">
                                    </picture>
                                </slide>
                                <hooper-pagination slot="hooper-addons"></hooper-pagination>
                            </hooper>
                        </div>
                    </div>
                    <!--Section carousel 2-->
                </div>

                <div class="slide slider-item" data-anchor="section-poste-resident">
                    <!-- Section poste résident -->
                    <div class="page-section section-poste-resident" @wheel.prevent="handleWheelMainCompo">
                        <div class="page-section-col">
                            <div class="block-amenagement-dynamique">
                                <div class="block-top-amenagement">
                                    <div class="block-top-title">
                                        <h4 class="title-h2 anim-txt-focus">
                                            <div>{{$t('titleAmenagementDynamique')['0']}}</div>
                                            <div class="color-turquoise">{{$t('titleAmenagementDynamique')['1']}}</div>
                                        </h4> 
                                    </div>
                                    <div class="block-title-r4">
                                        <span class="block-r4-text">{{$t('rAmenagement')}}</span>
                                    </div>
                                    <div class="block-top-image"> 
                                        <picture class="loadTemp"> 
                                            <source srcset="" :dta-srcset='this.$pathprod+"images/composition-inclusive/composition-inclusive-poste-resident2.webp"' type="image/webp">
                                            <img src="" :dta-src='this.$pathprod+"images/composition-inclusive/composition-inclusive-poste-resident2.png"' alt="composition-inclusive-poste-resident" width="920" height="581">
                                        </picture>
                                    </div>
                                </div>
                                <div class="block-bottom-amenagement">
                                    <div class="block-bottom-col">
                                        <ul class="list-arrow list-black">
                                            <li>{{$t('dataAmenagementDynamique')['0']}} <strong>5 739 m<span class="m2-custom-txt">2</span> *</strong></li>
                                            <li>{{$t('dataAmenagementDynamique')['1']}} <strong>5 170 m<span class="m2-custom-txt">2</span></strong></li>
                                            <li>{{$t('dataAmenagementDynamique')['2']}} <strong>90%</strong></li>
                                            <li>{{$t('dataAmenagementDynamique')['3']}} <strong>{{$t('dataAmenagementDynamique')['4']}}</strong></li>
                                            <li><strong>{{$t('dataAmenagementDynamique')['5']}}</strong> <br>{{$t('dataAmenagementDynamique')['6']}}</li>
                                            <li>{{$t('dataAmenagementDynamique')['7']}} <br><strong>9,9 m<span class="m2-custom-txt">2</span></strong></li>
                                            <li>{{$t('dataAmenagementDynamique')['8']}} <strong>8,4 m<span class="m2-custom-txt">2</span></strong></li>
                                            <li>{{$t('dataAmenagementDynamique')['9']}} <strong>1,35 m</strong></li>
                                            <li>{{$t('dataAmenagementDynamique')['10']}} <strong>19 m</strong></li>
                                            <li>{{$t('dataAmenagementDynamique')['11']}} <strong>2,70 m</strong></li>
                                        </ul>
                                        <p class="indice-text">{{$t('dataAmenagementDynamique')['12']}}</p>
                                    </div>
                                    <div class="block-bottom-col fade-in-right">
                                        <picture class="loadTemp"> 
                                            <source srcset="" :dta-srcset='this.$pathprod+"images/composition-inclusive/composition-inclusive-poste-resident2.webp"' type="image/webp">
                                            <img src="" :dta-src='this.$pathprod+"images/composition-inclusive/composition-inclusive-poste-resident2.png"' alt="composition-inclusive-poste-resident" width="920" height="581">
                                        </picture>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- Section poste résident -->
                </div>

                <div class="slide slider-item" data-anchor="roof-top">
                    <div class="page-section section-rooftop section-roofscroll" @wheel.prevent="wheelIntersectionEspaceRoofTop" id="top-roof">
                        <div class="dynamique-images-block">
                            <div class="dynamique-images-flou dynamic-images drop-attachment-fixed">
                                <picture class="loadTemp"> 
                                    <source srset="" :dta-srcset='this.$pathprod+"images/composition-inclusive/composition-inclusive-rooftop-bg-1.webp"' type="image/webp">
                                    <img src="" :dta-src='this.$pathprod+"images/composition-inclusive/composition-inclusive-rooftop-bg-1.jpg"' srcset="" :dta-srcset='this.$pathprod+"images/composition-inclusive/composition-inclusive-rooftop-bg-1-retina.jpg 2x"' alt="composition-inclusive-rooftop-bg-1" width="1920" height="937">
                                </picture>
                                <div class="block-turquoise-dynamique">
                                    <p class="block-gold-text">{{$t('titleRooftop')}}</p>
                                </div>
                            </div>
                            
                            <div class="dynamique-images-full dynamic-images drop-attachment-fixed">
                                <picture class="loadTemp"> 
                                    <source srcset="" :dta-srcset='this.$pathprod+"images/composition-inclusive/composition-inclusive-rooftop-bg-2.webp"' type="image/webp">
                                    <img src="" :dta-src='this.$pathprod+"images/composition-inclusive/composition-inclusive-rooftop-bg-2.jpg"' srcset="" :dta-srcset='this.$pathprod+"images/composition-inclusive/composition-inclusive-rooftop-bg-2-retina.jpg 2x"' alt="composition-inclusive-rooftop-bg-2" width="1920" height="937">
                                </picture>
                            </div>
                            <div class="block-key-number" :class="{'increment': incrementE}">
                                    <div class="block-key-left"></div>
                                    <div class="block-key-right">
                                    <div class="block-turquoise-number">
                                        <div class="block-turquoise-image">
                                            <picture class="loadTemp"> 
                                                <source srcset="" :dta-srcset='this.$pathprod+"images/composition-inclusive/composition-inclusive-rooftop-bg-3.webp"' type="image/webp">
                                                <img src="" :dta-src='this.$pathprod+"images/composition-inclusive/composition-inclusive-rooftop-bg-3.png"' srcset="" :dta-srcset='this.$pathprod+"images/composition-inclusive/composition-inclusive-rooftop-bg-3-retina.png 2x"' alt="composition-inclusive-rooftop-bg-3" class="img-plan" width="820" height="518">
                                            </picture>
                                        </div>
                                        <div class="block-gold-item">
                                            <h4 class="title-h4-white">{{$t('dataRooftop')['0']}}</h4>
                                        </div>
                                        <div class="block-gold-item">
                                            <h4 class="title-h4-white">7 200 m<span class="m2-custom-txt subtitle-m2">2</span> {{$t('dataRooftop')['1']}} m<span class="m2-custom-txt subtitle-m2">2</span> {{$t('dataRooftop')['2']}} </h4>
                                        </div>
                                        <div class="block-gold-item">
                                            <ul class="list-arrow">
                                                <li>600 m<span class="m2-custom-txt">2</span> {{$t('dataChiffreKeyRoofTop')['1']}}</li>
                                                <li>340 m {{$t('dataChiffreKeyRoofTop')['2']}}</li>
                                                <li>2 300 m<span class="m2-custom-txt">2</span> {{$t('dataChiffreKeyRoofTop')['3']}}<br/> dont 200 m<span class="m2-custom-txt">2</span> {{$t('dataChiffreKeyRoofTop')['0']}}</li>
                                                <li>{{$t('dataChiffreKeyRoofTop')['4']}}</li>
                                                <li>{{$t('dataChiffreKeyRoofTop')['5']}}</li>
                                            </ul>
                                        </div>
                                    </div>
                                    </div>
                                </div>
                        </div>
                    </div>
                </div>

                <div class="slide slider-item" data-anchor="section-carousel3">
                    <!--Section carousel 3-->
                    <div class="page-section section-carousel" @wheel.prevent="wheelIntersectionCarousel($event, carousel3_block, 'carousel', 3)">
                        <div class="page-section-col">

                            <hooper ref="carousel3" class="slider-block carousel3-block" :settings="hooperSettings"> 
                                <slide class="slider-item" v-for="carousel in carousel3" :key="carousel.id">
                                    <picture> 
                                        <source :srcset="carousel.srcsetWebp" type="image/webp">
                                        <img  :src="carousel.src" :srcset="carousel.srcset" :alt="carousel.alt" @click="openLightbox3(carousel.id, lightbox3)">
                                    </picture>
                                </slide>
                                <hooper-pagination slot="hooper-addons"></hooper-pagination>
                            </hooper>
                        </div>
                    </div>
                    <!--Section carousel 3-->
                </div>
            </div>

        <!-- </hooper> -->
        </full-page>
        
        <!--LightBox Carousel 1-->
        <div class="lightbox lightbox-1" :class="{'show': showLightbox}">
            <a href="#" class="btn btn-white btn-close-lightbox" @click="closeLightbox">
                <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0.635934 13.364L13.3639 0.636039M0.635934 0.636039L13.3639 13.364" stroke="#F3C678" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
            </a>
            <div class="lightbox-slider" v-show="showLightbox">
                <div class="lightbox-item" v-for="carousel in carousel1" :key="carousel.id" :data-item="carousel.id">
                    <picture> 
                        <source :srcset="carousel.srcsetWebp" type="image/webp">
                        <img  :src="carousel.src" :srcset="carousel.srcset" :alt="carousel.alt" width="1920" height="937">
                    </picture>
                    <div class="legend-block">
                        <p class="legend-text">
                            <strong>{{$t('DataLightboxLegend1')[carousel.id-1]}}</strong>
                            {{$t('DataLightboxLegendSubNumber1')[carousel.id-1]}}<sup>{{$t('DataLightboxLegendSubIndice1')[carousel.id-1]}}</sup>
                            <span>{{$t('DataLightboxLegendSubTxt1')[carousel.id-1]}}</span>
                        </p>
                    </div>
                </div>
            </div>
            <div class="nav-lightbox">
                <a href="#" class="btn btn-white btn-nav-lightbox btn-l-prev" @click.prevent="goToprev(lightbox1)" :data-postion-active="priority" :class="{'disabled': disabled_prev}">
                    <svg width="22" height="12" viewBox="0 0 22 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M20.799 1L10.8995 10.8995L1 1" stroke="#F3C678" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                    <svg width="22" height="12" viewBox="0 0 22 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M20.799 1L10.8995 10.8995L1 1" stroke="#F3C678" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                </a>
                <a href="#" class="btn btn-white btn-nav-lightbox btn-l-next" @click.prevent="goTonext(lightbox1)" :data-postion-active="priority" :class="{'disabled': disabled_next}">
                    <svg width="22" height="12" viewBox="0 0 22 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M20.799 1L10.8995 10.8995L1 1" stroke="#F3C678" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                    <svg width="22" height="12" viewBox="0 0 22 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M20.799 1L10.8995 10.8995L1 1" stroke="#F3C678" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                </a>
            </div>
        </div>
        <!--LightBox Carousel 1-->

        <!--LightBox Carousel 2-->
        <div class="lightbox lightbox-2" :class="{'show': showLightbox2}">
            <a href="#" class="btn btn-white btn-close-lightbox" @click="closeLightbox2">
                <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0.635934 13.364L13.3639 0.636039M0.635934 0.636039L13.3639 13.364" stroke="#F3C678" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
            </a>
            <div class="lightbox-slider" v-show="showLightbox2">
                <div class="lightbox-item" v-for="carousel in carousel2" :key="carousel.id" :data-item="carousel.id">
                    <picture> 
                        <source :srcset="carousel.srcsetWebp" type="image/webp">
                        <img  :src="carousel.src" :srcset="carousel.srcset" :alt="carousel.alt" width="1920" height="937">
                    </picture>
                    <div class="legend-block">
                        <p class="legend-text">
                            <strong>{{$t('DataLightboxLegend2')[carousel.id-1]}}</strong>
                            {{$t('DataLightboxLegendSubNumber2')[carousel.id-1]}}<sup>{{$t('DataLightboxLegendSubIndice2')[carousel.id-1]}}</sup>
                            <span>{{$t('DataLightboxLegendSubTxt2')[carousel.id-1]}}</span>
                        </p>
                    </div>
                </div>
            </div>
            <div class="nav-lightbox">
                <a href="#" class="btn btn-white btn-nav-lightbox btn-l-prev" @click.prevent="goToprev(lightbox2)" :data-postion-active="priority2" :class="{'disabled': disabled_prev}">
                    <svg width="22" height="12" viewBox="0 0 22 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M20.799 1L10.8995 10.8995L1 1" stroke="#F3C678" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                    <svg width="22" height="12" viewBox="0 0 22 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M20.799 1L10.8995 10.8995L1 1" stroke="#F3C678" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                </a>
                <a href="#" class="btn btn-white btn-nav-lightbox btn-l-next"  @click.prevent="goTonext(lightbox2)" :data-postion-active="priority2" :class="{'disabled': disabled_next}">
                    <svg width="22" height="12" viewBox="0 0 22 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M20.799 1L10.8995 10.8995L1 1" stroke="#F3C678" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                    <svg width="22" height="12" viewBox="0 0 22 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M20.799 1L10.8995 10.8995L1 1" stroke="#F3C678" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                </a>
            </div>
        </div>
        <!--LightBox Carousel 2-->

        <!--LightBox Carousel 3-->
        <div class="lightbox lightbox-3" :class="{'show': showLightbox3}">
            <a href="#" class="btn btn-white btn-close-lightbox" @click="closeLightbox3">
                <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0.635934 13.364L13.3639 0.636039M0.635934 0.636039L13.3639 13.364" stroke="#F3C678" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
            </a>
            <div class="lightbox-slider" v-show="showLightbox3">
                <div class="lightbox-item" v-for="carousel in carousel3" :key="carousel.id" :data-item="carousel.id">
                    <picture> 
                        <source :srcset="carousel.srcsetWebp" type="image/webp">
                        <img :src="carousel.src" :srcset="carousel.srcset" :alt="carousel.alt">
                    </picture>
                    <div class="legend-block">
                        <p class="legend-text">
                            <strong>{{$t('DataLightboxLegend3')[carousel.id-1]}}</strong>
                            {{$t('DataLightboxLegendSubNumber3')[carousel.id-1]}}<sup>{{$t('DataLightboxLegendSubIndice3')[carousel.id-1]}}</sup>
                            <span>{{$t('DataLightboxLegendSubTxt3')[carousel.id-1]}}</span>
                        </p>
                    </div>
                </div>
            </div>
            <div class="nav-lightbox">
                <a href="#" class="btn btn-white btn-nav-lightbox btn-l-prev" @click.prevent="goToprev(lightbox3)" :data-postion-active="priority3" :class="{'disabled': disabled_prev}">
                    <svg width="22" height="12" viewBox="0 0 22 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M20.799 1L10.8995 10.8995L1 1" stroke="#F3C678" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                    <svg width="22" height="12" viewBox="0 0 22 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M20.799 1L10.8995 10.8995L1 1" stroke="#F3C678" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                </a>
                <a href="#" class="btn btn-white btn-nav-lightbox btn-l-next"  @click.prevent="goTonext(lightbox3)" :data-postion-active="priority3" :class="{'disabled': disabled_next}">
                    <svg width="22" height="12" viewBox="0 0 22 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M20.799 1L10.8995 10.8995L1 1" stroke="#F3C678" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                    <svg width="22" height="12" viewBox="0 0 22 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M20.799 1L10.8995 10.8995L1 1" stroke="#F3C678" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                </a>
            </div>
        </div>
        <!--LightBox Carousel 3-->
        </div>
    </div>
    <div v-else>
        <Compositionmobile/>
    </div>
</div>
    
    
</template>

<script>
import {Hooper, Slide, Pagination as HooperPagination} from 'hooper';
import 'hooper/dist/hooper.css';
import _ from 'lodash'

const arrayCarousel1 = [
    {id: 1, src: "/images/composition-inclusive/carousel/Interface-Bureaux-Paris-01.jpg", srcset:"/images/composition-inclusive/carousel/Interface-Bureaux-Paris-01-retina.jpg 2x" , srcsetWebp:"/images/composition-inclusive/carousel/Interface-Bureaux-Paris-01.webp", alt: "Interface-Bureaux-Paris-01-V2-Hall-Lobby-051", legend: "Le Lobby /", legendSub: "RDC"},
    {id: 2, src: "/images/composition-inclusive/carousel/Interface-Bureaux-Paris-02.jpg", srcset:"/images/composition-inclusive/carousel/Interface-Bureaux-Paris-02-retina.jpg 2x" , srcsetWebp:"/images/composition-inclusive/carousel/Interface-Bureaux-Paris-02.webp", alt: "Interface-Bureaux-Paris-Brulerie 06_BLEU", legend: "La Plaza /", legendSub: "RDC"},
    {id: 3, src: "/images/composition-inclusive/carousel/Interface-Bureaux-Paris-03.jpg", srcset:"/images/composition-inclusive/carousel/Interface-Bureaux-Paris-03-retina.jpg 2x" , srcsetWebp:"/images/composition-inclusive/carousel/Interface-Bureaux-Paris-03.webp", alt: "Interface-Bureaux-Paris-05-V2 Scramble-hdv7", legend: "La cuisine du marché /", legendSub: "RDC" },
    {id: 4, src: "/images/composition-inclusive/carousel/Interface-Bureaux-Paris-04.jpg", srcset:"/images/composition-inclusive/carousel/Interface-Bureaux-Paris-04-retina.jpg 2x" , srcsetWebp:"/images/composition-inclusive/carousel/Interface-Bureaux-Paris-04.webp", alt: "Interface-Bureaux-Paris-07-V2-Rie-La Grande-salle-06", legend: "Le Restaurant /", legendSub: "RDC" },
    {id: 5, src: "/images/composition-inclusive/carousel/Interface-Bureaux-Paris-05.jpg", srcset:"/images/composition-inclusive/carousel/Interface-Bureaux-Paris-05-retina.jpg 2x" , srcsetWebp:"/images/composition-inclusive/carousel/Interface-Bureaux-Paris-05.webp", alt: "Interface-Bureaux-Paris-11-V2-Bureau R+1-04", legend: "Les Kiosques /", legendSub: "RDC" },
    {id: 6, src: "/images/composition-inclusive/carousel/Interface-Bureaux-Paris-06.jpg", srcset:"/images/composition-inclusive/carousel/Interface-Bureaux-Paris-06-retina.jpg 2x" , srcsetWebp:"/images/composition-inclusive/carousel/Interface-Bureaux-Paris-06.webp", alt: "Interface-Bureaux-Paris-Rooftop 07", legend: "L’auditorium /", legendSub: "R+1" },
    {id: 7, src: "/images/composition-inclusive/carousel/Interface-Bureaux-Paris-sport-8.jpg", srcset:"/images/composition-inclusive/carousel/Interface-Bureaux-Paris-sport-retina.jpg 2x" , srcsetWebp:"/images/composition-inclusive/carousel/Interface-Bureaux-Paris-sport-8.webp", alt: "Interface-Bureaux-Paris-Rooftop 8", legend: "Les Studios /", legendSub: "R+1" },
];

const arrayCarousel2 = [
    {id: 1, src: "/images/composition-inclusive/carousel/Interface-Bureaux-Paris-c3.jpg", srcset:"/images/composition-inclusive/carousel/Interface-Bureaux-Paris-c3-retina.jpg 2x", srcsetWebp:"/images/composition-inclusive/carousel/Interface-Bureaux-Paris-c3.webp", alt: "Interface-Bureaux-Paris-Brulerie 06_BLEU3", legend: "Les bureaux /", legendSub: "R+3"},
    {id: 2, src: "/images/composition-inclusive/carousel/Interface-Bureaux-Paris-c2.jpg", srcset:"/images/composition-inclusive/carousel/Interface-Bureaux-Paris-c2-retina.jpg 2x", srcsetWebp:"/images/composition-inclusive/carousel/Interface-Bureaux-Paris-c2.webp", alt: "Interface-Bureaux-Paris-Brulerie 06_BLEU2", legend: "Les bureaux /", legendSub: "R+6"},
];

const arrayCarousel3 = [
    {id: 1, src: "/images/composition-inclusive/carousel/Interface-Bureaux-Paris-d1.jpg",srcset:"/images/composition-inclusive/carousel/Interface-Bureaux-Paris-d1-retina.jpg 2x", srcsetWebp:"/images/composition-inclusive/carousel/Interface-Bureaux-Paris-d1.webp",  alt: "Interface-Bureaux-Paris-01-V2-Hall-Lobby 6", legend: "Le Rooftop /", legendSub: "R+8"},
    {id: 2, src: "/images/composition-inclusive/carousel/Interface-Bureaux-Paris-d2.jpg",srcset:"/images/composition-inclusive/carousel/Interface-Bureaux-Paris-d2-retina.jpg 2x", srcsetWebp:"/images/composition-inclusive/carousel/Interface-Bureaux-Paris-d2.webp", alt: "Interface-Bureaux-Paris-Brulerie 06_BLEU_2", legend: "Le Rooftop /", legendSub: "R+8"},
];

 const contentTable = [
    {niveau: "R+9", sub: "", capacitaire: "", service: "", subl: "", surface: ""}, 
    {niveau: "R+8", sub: "", capacitaire: "", service: "", subl: "", surface: "3 725"}, 
    {niveau: "R+7", sub: "4 675", capacitaire: "495", service: "1 054", subl: "5 729", surface: "76"}, 
    {niveau: "R+6", sub: "4 680", capacitaire: "498", service: "1 055", subl: "5 735", surface: "390"}, 
    {niveau: "R+5", sub: "4 640", capacitaire: "497", service: "1 046", subl: "5 686", surface: "379"}, 
    {niveau: "R+4", sub: "4 953", capacitaire: "531", service: "1 117", subl: "6 069", surface: "583"}, 
    {niveau: "R+3", sub: "4 445", capacitaire: "582", service: "1 228", subl: "6 673", surface: "58"}, 
    {niveau: "R+2", sub: "5 549", capacitaire: "588", service: "1 251", subl: "6 800", surface: "57"}, 
    {niveau: "R+1", sub: "5 510", capacitaire: "588", service: "1 242", subl: "6 752", surface: "581"}, 
    { niveau: "RDC/Mezz", sub: "", capacitaire: "17", service: "", subl: "", surface: "1 378"}, 
];

export default {
  name: 'CompositionInclusive',
  components: {
    Hooper,
    Slide,
    HooperPagination,
    Compositionmobile: () => import('@/components/Compositionmobile.vue'),
    VideoCompositionInclusive: () => import('@/components/video/VideoCompositionInclusive.vue'),
  },
  props: {
    msg: String
  },

    metaInfo() {
        return { 
            title: "Rhapsody - Composition inclusive",
            meta: [
                { name: 'description', content:  'Rhapsody, de l’esquisse au projet. Retrouvez les différents espaces au sien de RHAPSODY, en passant par le lobby jusqu’au rooftop de 3700 m2.'},
                { property: 'og:title', content: "Rhapsody - Composition inclusive"},
                { property: 'og:site_name', content: 'Rhapsody'},
                { property: 'og:type', content: 'website'},    
            ]
        }
    },
    data(){
        return{
        isLoad: false,
        init: 100,
        init2: 100,
        init3: 100,
        initCalc: 250,
        initCalc2: 250,
        initCalc3: 250,
        componentKey: 0,
        tableVivre: contentTable,
        scroll: null,
        carousel1: arrayCarousel1,
        carousel2: arrayCarousel2,
        carousel3: arrayCarousel3,
        incrementE: false,
        increment: false,
        showLightbox:false,
        showLightbox2:false,
        showLightbox3:false,
        showBrushK:false,
        showBrush:false,
        priority:1,
        priority2:1,
        priority3:1,
        valUp: false,
        valDown: false,
        lightbox1: ".lightbox-1",
        lightbox2: ".lightbox-2",
        lightbox3: ".lightbox-3",
        section_corner: ".section-corner",
        section_plaza: ".section-plaza",
        carousel1_block: ".carousel1-block",
        carousel2_block: ".carousel2-block",
        carousel3_block: ".carousel3-block",
        section_espaces_rass: ".section-espaces-rass",
        section_espace_poll: ".section-espace-poll",
        section_roofscroll: ".section-roofscroll",
        disabled_next: false,
        disabled_prev: false,
        bottomPos_esquiss: null,
        settings: {
            "dots": true,
            "infinite": false,
            "arrows":false,
            "centerMode": true,
            "vertical" : true,
            "slidesToShow": 1,
            "slidesToScroll": 1,
            "initialSlide": 0,
            "centerPadding": "100px"
        },
        hooperSettings: {
                itemsToShow: 1.25,
                centerMode: true,
                vertical: true,         
                wheelControl: false,
                transition: 1200,
                touchDrag: false,
                shortDrag: false,
                trimWhiteSpace: true,
                breakpoints: {
                320: {
                    centerMode: false,
                    itemsToShow: 3
                },
                992: {
                    itemsToShow: 1.25,
                    pagination: 'fraction'
                }
            },
        },
        hooperMainSettings: {
            itemsToShow: 1,
            transition: 1000,                
            wheelControl: false,
            touchDrag: false,
            shortDrag: false,
            breakpoints: {
                320: {
                    itemsToShow: 1,
                },
                992: {
                    itemsToShow: 1,
                },
                1440: {
                    itemsToShow: 1,
                }
            }
        },
            AnimtxtBlur: false,
            showBloc: false,
            options: {
                licenseKey: 'YOUR_KEY_HEERE',
                scrollingSpeed: 1500,
                menu: '#menuPage',
                touchSensitivity: 1,
                loopHorizontal: false,
                lockAnchors: true,
                slidesNavigation: true,
                slidesNavPosition: 'bottom',
                keyboardScrolling: true,
                isMove: false,
                interupteur: false,
            },
            time: 0,
            isTrackpad: false,
        }
    },
    created () {
        window.addEventListener('resize', this.handleresize);
        this.throttleWheelPrev = _.debounce(this.throttleWheelPrev, 400);
        this.throttleWheelNext = _.debounce(this.throttleWheelNext, 400);
        this.throttleWheelSlidePrev = _.debounce(this.throttleWheelSlidePrev, 400);
        this.throttleWheelSlideNext = _.debounce(this.throttleWheelSlideNext, 400);
    },
    destroyed () {
        window.removeEventListener('resize', this.handleresize);
        this.$el.remove();
    },
    beforeDestroy(){
        this.$el.remove();
    },
    methods:{
        anchorSlide(){
            //check page saintOuen isMounted
            var dotList = document.querySelectorAll(".fp-slidesNav .fp-sr-only")

            if(dotList){
               for (const dot of dotList) {
                  var txt_anchor = dot.textContent
                  dot.parentElement.href = "#" + txt_anchor
               }
            }
        },

        handleWheelMainCompo(e){
            var sctBeforeAfter1 = document.querySelectorAll('.fp-table')[4].classList.contains("active")
            var sctBeforeAfter3 = document.querySelectorAll('.fp-table')[14].classList.contains("active")
            //TrackPad tactile wheel
            if (e.wheelDeltaY) {
                var deltaValueIsFloat = Number(e.deltaY * -3) === e.deltaY * -3 && e.deltaY * -3 % 1 !== 0
                if (!deltaValueIsFloat && e.wheelDeltaY == -120 || e.wheelDeltaY == 120) {
                    this.isTrackpad = false;
                }else{
                    this.isTrackpad = true;
                }
            }
            if(this.isMove){
                return false
            }else{
                if(e.deltaY < 0){
                    this.isWheel()
                    document.querySelector('.slider-block-composition .fp-prev').click();
                }
                else{
                    if(sctBeforeAfter1 || sctBeforeAfter3){
                        return;
                    }else{
                        this.isWheel()
                        document.querySelector('.slider-block-composition .fp-next').click();
                    }
                    
                }
            }
        },

        theFormat(number) {
            return this.numberWithSpaces(number.toFixed(0));
        },

        theFormatDecimal(number) {
            return number.toFixed(2);
        },
        
        playAnimationRassemebleurs() {
            this.$refs.number1.play()
            this.$refs.number2.play()
            this.$refs.number3.play()
            this.$refs.number4.play()
        },

        playAnimationPoll() {
            this.$refs.number5.play()
            this.$refs.number6.play()
            this.$refs.number7.play()
            this.$refs.number8.play()
            this.$refs.number9.play()
            this.$refs.number10.play()
        },

        isMobile() {
            if( screen.width <= 1025 ) {
                return true;
            }
            else {
                return false;
            }
        },

        handleresize(){
            this.componentKey =+ 1;
            this.isMobile();
        },

        /*fonction get data number element*/
        getDataNumberElement(itemLightBox){
            let idr = 0;
            for (var i = 0, len = itemLightBox.length; i < len; i++) {
                if(itemLightBox[i].classList.contains('active')){
                    idr = parseInt(itemLightBox[i].getAttribute('data-item'));
                }
            }
            return idr;
        },

        scrollToNext(e){
            e.preventDefault();
            document.querySelector('.slider-block-composition .fp-next').click();
        },

        /*************************/
        /*   GESTION LIGHTBOX  */
        /*************************/
        /*Prev lightbox item*/
        goToprev(lightbox){
            let itemLightBox = document.querySelectorAll(lightbox + ' .lightbox-item');
            let nbitem = itemLightBox.length;
            let idActuel = this.getDataNumberElement(itemLightBox);
            
            if(idActuel <= nbitem && idActuel > 1){
                itemLightBox[idActuel - 1].classList.remove('active');
                itemLightBox[idActuel - 2].classList.add('active');
                this.priority = idActuel - 1;
            }

            this.disabled_prev = (idActuel-1)===1;
            this.disabled_next = (idActuel-1)===nbitem;
        },

        /*Next lightbox item*/
        goTonext(lightbox){
            let itemLightBox = document.querySelectorAll(lightbox + ' .lightbox-item');
            let nbitem = itemLightBox.length;
            let idActuel = this.getDataNumberElement(itemLightBox);
            if(idActuel < nbitem){
                itemLightBox[idActuel - 1].classList.remove('active');
                itemLightBox[idActuel].classList.add('active');
                this.priority = idActuel + 1;
            }
            
            this.disabled_next = (idActuel+1)===nbitem;
            this.disabled_prev = (idActuel+1)===1;
        },

        /*Fonction openLightBox*/
        openLightbox(id, lightbox){
            this.showLightbox = true;
            document.body.classList.add('open-menu');
            let itemLightBox = document.querySelectorAll('.lightbox-item');
            for (var i = 0, len = itemLightBox.length; i < len; i++) {
                if(parseInt(itemLightBox[i].getAttribute('data-item')) == id){
                    itemLightBox[i].classList.add('active');
                    document.querySelector('.btn-l-prev').setAttribute('data-postion-active',id); 
                    document.querySelector('.btn-l-next').setAttribute('data-postion-active',id);
                    this.priority = id;
                }
            }

            /**** CHECK BTN PREV */
            let check_LightBox = document.querySelectorAll(lightbox + ' .lightbox-item');
            let nbitem = check_LightBox.length;
            let idActuel = this.getDataNumberElement(check_LightBox);
            
            this.disabled_prev = (idActuel)===1;
            this.disabled_next = (idActuel)===nbitem;
        },

        /*Fonction closeLightbox*/
        closeLightbox(e){
            e.preventDefault();
            this.showLightbox = false;
            document.body.classList.remove('open-menu');
            let itemLightBox = document.querySelectorAll('.lightbox-item');
            for (var i = 0, len = itemLightBox.length; i < len; i++) {
                itemLightBox[i].classList.remove('active'); 
            }
            this.priority = 1;
        },

        /*Fonction openLightBox*/
        openLightbox2(id, lightbox){
            this.showLightbox2 = true;
            document.body.classList.add('open-menu');
            let itemLightBox = document.querySelectorAll('.lightbox-item');
            for (var i = 0, len = itemLightBox.length; i < len; i++) {
                if(parseInt(itemLightBox[i].getAttribute('data-item')) == id){
                    itemLightBox[i].classList.add('active');
                    document.querySelector('.btn-l-prev').setAttribute('data-postion-active',id); 
                    document.querySelector('.btn-l-next').setAttribute('data-postion-active',id);
                    this.priority2 = id;
                }
            }
            /**** CHECK BTN PREV */
            let check_LightBox = document.querySelectorAll(lightbox + ' .lightbox-item');
            let nbitem = check_LightBox.length;
            let idActuel = this.getDataNumberElement(check_LightBox);
            
            this.disabled_prev = (idActuel)===1;
            this.disabled_next = (idActuel)===nbitem;
        },

        /*Fonction closeLightbox*/
        closeLightbox2(e){
            e.preventDefault();
            this.showLightbox2 = false;
            document.body.classList.remove('open-menu');
            let itemLightBox = document.querySelectorAll('.lightbox-item');
            for (var i = 0, len = itemLightBox.length; i < len; i++) {
                itemLightBox[i].classList.remove('active'); 
            }
            this.priority2 = 1;
        },

        /*Fonction openLightBox*/
        openLightbox3(id, lightbox){
            this.showLightbox3 = true;
            document.body.classList.add('open-menu');
            let itemLightBox = document.querySelectorAll('.lightbox-item');
            for (var i = 0, len = itemLightBox.length; i < len; i++) {
                if(parseInt(itemLightBox[i].getAttribute('data-item')) == id){
                    itemLightBox[i].classList.add('active');
                    document.querySelector('.btn-l-prev').setAttribute('data-postion-active',id); 
                    document.querySelector('.btn-l-next').setAttribute('data-postion-active',id);
                    this.priority3 = id;
                }
            }
            /**** CHECK BTN PREV */
            let check_LightBox = document.querySelectorAll(lightbox + ' .lightbox-item');
            let nbitem = check_LightBox.length;
            let idActuel = this.getDataNumberElement(check_LightBox);
            
            this.disabled_prev = (idActuel)===1;
            this.disabled_next = (idActuel)===nbitem;
        },

        /*Fonction closeLightbox*/
        closeLightbox3(e){
            e.preventDefault();
            this.showLightbox3 = false;
            document.body.classList.remove('open-menu');
            let itemLightBox = document.querySelectorAll('.lightbox-item');
            for (var i = 0, len = itemLightBox.length; i < len; i++) {
                itemLightBox[i].classList.remove('active'); 
            }
            this.priority3 = 1;
        },

        stopBlockPropagation(e){
            e.stopPropagation();
        },

        addFormatNumber(el){
        let item = document.querySelectorAll(el);
        for(let i = 0; i < item.length; i++){
            item[i].innerText = this.numberWithSpaces(item[i].innerText);
        }
        },

        numberWithSpaces(x) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
        },

        wheelIntersection(e){
            e.stopPropagation();

            let keyNumberBlock = document.querySelector('.page-section-scrollable');
            var scrollTop_numberBloc = (Math.floor(keyNumberBlock.scrollTop));
            var scrollBottom_numberBloc = (Math.floor(keyNumberBlock.scrollHeight - keyNumberBlock.offsetHeight));
            // var esquisse_section = document.querySelectorAll(".fp-table")[2].classList.contains("active")
            var esquisse_sct_scrollable = document.querySelector(".page-section-scrollable")

            //Add overflow-y if section-esquisse is active
            // esquisse_section ? setTimeout(() => {esquisse_sct_scrollable.style.overflowY = "auto";}, 100) : esquisse_sct_scrollable.style.overflowY = "hidden" 

            esquisse_sct_scrollable.style.overflowY = "auto";
            if(this.isMove){
                return false
            }else{               
                //Scroll Top
                if(e.deltaY < 0){
                    this.isWheel()
                    if(scrollTop_numberBloc === 0){
                        document.querySelector('.slider-block-composition .fp-prev').click(); 
                    }
                }
                //Scroll Bottom
                else{
                    this.isWheel()
                    var old_value = scrollTop_numberBloc
                    var tmp_value = old_value
                    old_value = this.bottomPos_esquiss
                    this.bottomPos_esquiss = tmp_value
                    
                    if(scrollTop_numberBloc >= scrollBottom_numberBloc || old_value == this.bottomPos_esquiss && old_value != 0){
                        document.querySelector('.slider-block-composition .fp-next').click();
                    }
                }
            }
        },

        /*************************/
        /*   WHEEL INTERSECTION  */
        /*************************/
        wheelIntersectionImg(e, bloc_intersection){
            e.stopPropagation();
            var block_img_full = document.querySelector(bloc_intersection + ' .block-image-full')
            var block_img_transparent = document.querySelector(bloc_intersection + ' .block-image-transparent')
            var zoomImg1 = document.querySelectorAll(".fp-table")[5].classList.contains("active")
            var zoomImg2 = document.querySelectorAll(".fp-table")[8].classList.contains("active")

            if(this.isMove){
                return false
            }else{
                this.isWheel()
                if(zoomImg1 || zoomImg2){
                    if (e.deltaY < 0) {
                        this.isWheel()
                          block_img_full.classList.remove('animate');
                          block_img_transparent.classList.remove('animate');
                        setTimeout(function(){
                          block_img_transparent.classList.add('end-section');
                          block_img_full.classList.remove('end-section');
                        },600);

                        if(block_img_transparent.classList.contains('end-section')){
                            this.isWheel()
                            var sctBeforeAfter1 = document.querySelectorAll('.fp-table')[4].classList.contains("active")
                            var sctBeforeAfter3 = document.querySelectorAll('.fp-table')[14].classList.contains("active")

                            if(sctBeforeAfter1 || sctBeforeAfter3){
                                return
                            }else{
                                // document.querySelector('.slider-block-composition .fp-prev').click();  
                                this.debounceWheelPrev();
                            }
                        }
                    } else if (e.deltaY > 0) {
                          this.isWheel()
                          block_img_transparent.classList.add('animate');
                          block_img_full.classList.add('animate');
                        setTimeout(function(){
                            block_img_full.classList.add('end-section');
                            block_img_transparent.classList.remove('end-section');
                        },600);

                        if(block_img_full.classList.contains('end-section')){
                            // document.querySelector('.slider-block-composition .fp-next').click(); 
                            this.debounceWheelNext();
                        }
                    }
                }
            }
        },

        /** DebounceMethod Prev/Next slide and section**/
        debounceWheelPrev(){
            document.querySelector('.slider-block-composition .fp-prev').click(); 
        },

        debounceWheelNext(){
            document.querySelector('.slider-block-composition .fp-next').click(); 
        },

        debounceWheelSlidePrev(reference, idref){
            this.$refs[reference+idref].slidePrev(); 
        },

        debounceWheelSlideNext(reference, idref){
            this.$refs[reference+idref].slideNext();
        },

        /** ThrottleMethod Prev/Next slide and section**/
        throttleWheelPrev(){
            document.querySelector('.slider-block-composition .fp-prev').click(); 
        },

        throttleWheelNext(){
            document.querySelector('.slider-block-composition .fp-next').click(); 
        },

        throttleWheelSlidePrev(reference, idref){
            this.$refs[reference+idref].slidePrev(); 
        },

        throttleWheelSlideNext(reference, idref){
            this.$refs[reference+idref].slideNext();
        },

        /***********************************/
        /*   WHEEL INTERSECTION CAROUSEL  */
        /*********************************/
        wheelIntersectionCarousel(e, bloc_caroussel,reference,idref){
            e.stopPropagation();
            var sliderItem = document.querySelectorAll(bloc_caroussel + ' .slider-item');
            var lenght_sliderItem = (sliderItem.length - 1) 
            if(this.isMove){
                return false
            }else{
                if(e.deltaY < 0){
                    
                    sliderItem[lenght_sliderItem].classList.remove("end-section");
                    if(sliderItem[0].classList.contains("is-active")){
                        sliderItem[0].classList.add('top-section');
                        if(sliderItem[0].classList.contains("top-section")){
                            this.isWheel()
                            document.querySelector('.slider-block-composition .fp-prev').click(); 
                        }
                    }
                    else{
                        this.isWheel()
                        this.debounceWheelSlidePrev(reference,idref)
                    }
                }
                else if(e.deltaY > 0 && bloc_caroussel != ".carousel3-block"){
                    sliderItem[0].classList.remove("top-section");
                    if(sliderItem[lenght_sliderItem].classList.contains("is-active") ){
                        sliderItem[lenght_sliderItem].classList.add("end-section");
                        if(sliderItem[lenght_sliderItem].classList.contains("end-section")){
                            this.isWheel()
                            document.querySelector('.slider-block-composition .fp-next').click();          
                        }
                    }
                    else{
                        this.isWheel()
                        this.debounceWheelSlideNext(reference,idref)
                    }
                }
                else if(e.deltaY > 0 && bloc_caroussel == ".carousel3-block"){
                        this.debounceWheelSlideNext(reference,idref)
                }
            }
        },

        wheelIntersectionDestination(e){
            e.deltaY < 0 ? document.querySelector('.slider-block-composition .fp-prev').click() : document.querySelector('.slider-block-composition .fp-next').click()
        },
        
        //***************************************************************************
        //Gestion timing scroll continue sur Trackpad et MagickScroll
        //***************************************************************************
        isWheel(){
            var nav = window.navigator.platform;
            if (nav == "Mac68K" || nav == "MacPPC" || nav == "MacIntel" || this.isTrackpad) {
                this.time = 1500;
            }else{
                this.time = 500;
            }
            this.isMove = true;
            setTimeout(() => {
                this.isMove = false;
            },this.time);
        },

        /*----------------------------- */
        //  ESPACE RASSEMBLEURS
        /*----------------------------- */
        wheelIntersectionEspaceRassembleurs(e){
            e.stopPropagation();
            let keyNumberBlock = document.querySelector('.section-espaces-rass');
            let espace_img_flou = document.querySelector('.section-espaces-rass .dynamique-images-flou')
            let espace_keyNumber = document.querySelector('.section-espaces-rass .block-key-number')
            var scrollTo;

            keyNumberBlock.scrollBy({
                left: e.deltaY,
                behavior: "smooth"
            });

            e.deltaY <= 0 ? scrollTo = "scrollTop" : scrollTo = "scrollBottom"

            if(this.isMove){
                return false
            }else{
                //Get scroll event up
                /*-------------------- */
                if(e.deltaY <= 0){
                    this.isWheel()
                    setTimeout(() => {
                        espace_keyNumber.classList.remove("displayKeynumber");
                        espace_keyNumber.classList.remove("isTop");
                    }, 200);
                  
                    //Scroll to prev section si la barre de separation se situe au Top
                    if(this.init >= 100){
                        this.isWheel()
                        document.querySelector('.slider-block-composition .fp-prev').click();
                        setTimeout(function(){
                            this.init = 100;
                            espace_img_flou.style.width = this.init + "vw";
                        },200)
                    } 

                    //Sinon la barre continue de monter
                    else if (this.init <= 100 && !espace_keyNumber.classList.contains("isTop")) {
                        this.isWheel()
                        this.init = 100;
                        espace_img_flou.style.width = this.init + "vw";
                    }
                    if(espace_keyNumber.classList.contains("displayKeynumber")){
                        this.isWheel()
                    }
                }
                
                //Get scroll event down
                /*-------------------- */
                else{
                    this.isWheel()
                    if(espace_keyNumber.classList.contains("displayKeynumber")){
                        setTimeout(() => {
                            document.querySelector('.slider-block-composition .fp-next').click();
                        }, 200);
                    }
                    else{
                        //Fix key Number padding-top to 350vh
                        this.stopBlockPropagation(e);
                        this.init = 0;
                        espace_img_flou.style.width = this.init + "vw";
                        
                        //Si bandeau est sur le top

                        if (this.init == 0) {
                            this.isWheel();
                            setTimeout(() => {
                                espace_keyNumber.classList.add("isTop");
                            }, 200);
                        

                            if (espace_keyNumber.classList.contains("isTop") && scrollTo == "scrollBottom"){
                                this.isWheel();
                                 espace_keyNumber.classList.add("displayKeynumber");
                                setTimeout(() => {
                                    this.playAnimationRassemebleurs();
                                }, 200);
                                return 0
                            }
                        }
                    }
                }
            }
        },

        /*----------------------------- */
        //  ESPACE ESPACE POLL
        /*----------------------------- */
        wheelIntersectionEspacePoll(e){
            e.stopPropagation();
            let keyNumberBlock = document.querySelector('.section-espace-poll');
            let espace_img_flou = document.querySelector('.section-espace-poll .dynamique-images-flou')
            let espace_keyNumber = document.querySelector('.section-espace-poll .block-key-number')
            var scrollTo;

            keyNumberBlock.scrollBy({
                left: e.deltaY,
                behavior: "smooth"
            });

            e.deltaY <= 0 ? scrollTo = "scrollTop" : scrollTo = "scrollBottom";

            if(this.isMove){
                return false
            }else{
                //Get scroll event up
                /*-------------------- */
                if(e.deltaY <= 0){
                    this.isWheel()
                    setTimeout(() => {
                        espace_keyNumber.classList.remove("displayKeynumber");
                        espace_keyNumber.classList.remove("isTop");
                    }, 200);
                  
                    //Scroll to prev section si la barre de separation se situe au Top
                    if(this.init2 >= 100){
                        this.isWheel()
                        document.querySelector('.slider-block-composition .fp-prev').click();
                        setTimeout(function(){
                            this.init2 = 100;
                            espace_img_flou.style.width = this.init2 + "vw";
                        },800)
                    } 
                    
                
                    //Sinon la barre continue de monter
                    else if (this.init2 <= 100 && !espace_keyNumber.classList.contains("isTop")) {
                        this.isWheel()
                        this.init2 = 100;
                        espace_img_flou.style.width = this.init2 + "vw";
                    }
                    if(espace_keyNumber.classList.contains("displayKeynumber")){
                        this.isWheel()
                    }
                }
                
                //Get scroll event down
                /*-------------------- */
                else{
                    if(espace_keyNumber.classList.contains("displayKeynumber")){
                        this.isWheel()
                        setTimeout(() => {
                            document.querySelector('.slider-block-composition .fp-next').click();
                        }, 500);
                    }
                    else{
                        //Fix key Number padding-top to 350vh
                        this.stopBlockPropagation(e);
                        this.init2 = 0;
                        espace_img_flou.style.width = this.init2 + "vw";
                        
                        //Si bandeau est sur le top

                        if (this.init2 == 0) {
                            setTimeout(() => {
                                espace_keyNumber.classList.add("isTop");
                            }, 500);
                            this.isWheel();

                            if (espace_keyNumber.classList.contains("isTop") && scrollTo == "scrollBottom"){
                                 espace_keyNumber.classList.add("displayKeynumber");
                                setTimeout(() => {
                                    this.playAnimationPoll();
                                }, 500);
                                return 0
                            }
                        }
                    }
                }
            }
        },

        /*----------------------------- */
        //  ESPACE ROOFTOP
        /*----------------------------- */
        wheelIntersectionEspaceRoofTop(e){
            e.stopPropagation();
            let keyNumberBlock = document.querySelector('.section-roofscroll');
            let espace_img_flou = document.querySelector('.section-roofscroll .dynamique-images-flou');
            let espace_keyNumber = document.querySelector('.section-roofscroll .block-key-number')
            var scrollTo;

            keyNumberBlock.scrollBy({
                left: e.deltaY,
                behavior: "smooth"
            });

            e.deltaY <= 0 ? scrollTo = "scrollTop" : scrollTo = "scrollBottom";

            if(this.isMove){
                return false
            }else{
                //Get scroll event up
                /*-------------------- */
                if(e.deltaY <= 0){
                    this.isWheel()
                    setTimeout(() => {
                        espace_keyNumber.classList.remove("displayKeynumber");
                        espace_keyNumber.classList.remove("isTop");
                    }, 700);
                  
                    //Scroll to prev section si la barre de separation se situe au Top
                    if(this.init3 >= 100){
                        this.isWheel()
                        document.querySelector('.slider-block-composition .fp-prev').click();
                        setTimeout(function(){
                            this.init3 = 100;
                            espace_img_flou.style.width = this.init3 + "vw";
                        },700)
                    } 
                
                    //Sinon la barre continue de monter
                    else if (this.init3 <= 100 && !espace_keyNumber.classList.contains("isTop")) {
                        this.isWheel()
                        this.init3 = 100;
                        espace_img_flou.style.width = this.init3 + "vw";
                    }
                    if(espace_keyNumber.classList.contains("displayKeynumber")){
                        this.isWheel()
                    }
                }
                
                //Get scroll event down
                /*-------------------- */
                else{
                    this.isWheel()
                    if(espace_keyNumber.classList.contains("displayKeynumber")){
                        setTimeout(() => {
                            document.querySelector('.slider-block-composition .fp-next').click();
                        }, 700);
                    }
                    else{
                        //Fix key Number padding-top to 350vh
                        this.stopBlockPropagation(e);
                        this.init3 = 0;
                        espace_img_flou.style.width = this.init3 + "vw";
                        
                        //Si bandeau est sur le top

                        if (this.init3 == 0) {
                            this.isWheel();
                            setTimeout(() => {
                                espace_keyNumber.classList.add("isTop");
                            }, 700);
                       

                            if (espace_keyNumber.classList.contains("isTop") && scrollTo == "scrollBottom"){
                                this.isWheel();
                                espace_keyNumber.classList.add("displayKeynumber");
                                return 0
                            }
                        }
                    }
                }
            }
        },

        lazyLoading(){
            /*Diffère tous les images*/
            setTimeout(function(){
                let loadImgSource = document.querySelectorAll('.loadTemp source');
                let loadImgImg = document.querySelectorAll('.loadTemp img');
                for(let i = 0; i < loadImgSource.length; i++){
                    loadImgSource[i].setAttribute('srcset',loadImgSource[i].getAttribute('dta-srcset'));
                    loadImgImg[i].setAttribute('src',loadImgImg[i].getAttribute('dta-src'));
                    loadImgImg[i].setAttribute('srcset',loadImgImg[i].getAttribute('dta-srcset'));
                }
            }.bind(this), 1200);
        }
    },

    mounted(){
        setTimeout(() => {
            this.AnimtxtBlur = true;
            this.showBloc = true;
        }, 3000);

        document.body.classList.remove('removeHiddenOverflow');

        //Play entete video if component is ready
        setTimeout(() => {
            var video = document.querySelector(".section-first .bg-hero")
            if(video){
                video.play()  
                
                var inter = setInterval(() =>{
                    if(video.paused && video.ended == false ){
                        video.play()
                    }
                }, 500);
                
                video.addEventListener("ended", function(){
                    clearInterval(inter)
                })
            }
        }, 1500);


        //Load asynchrone on componentsReady
        setTimeout(() => {
            this.anchorSlide()
        }, 1000);
        this.lazyLoading()
        
        //OnreSize reinject all img
        window.addEventListener("resize", function(){
            /*Diffère tous les images*/
            setTimeout(function(){
                let loadImgSource = document.querySelectorAll('.loadTemp source');
                let loadImgImg = document.querySelectorAll('.loadTemp img');
                for(let i = 0; i < loadImgSource.length; i++){
                    loadImgSource[i].setAttribute('srcset',loadImgSource[i].getAttribute('dta-srcset'));
                    loadImgImg[i].setAttribute('src',loadImgImg[i].getAttribute('dta-src'));
                    loadImgImg[i].setAttribute('srcset',loadImgImg[i].getAttribute('dta-srcset'));
                }
            }.bind(this), 1200);
        })
    },
}
</script>